.fixedSocialMediaBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  position: fixed;
  z-index: 100;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  padding: 15px 10px;
  padding-right: 12px;
  border: 1px solid #707070;
  background: #000;
  border-right: none;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.icon {
  font-size: 16px;
  color: rgb(29 204 29);
}
@media only screen and (max-width: 640px) {
  .fixedSocialMediaBar {
    display: none;
  }
}
