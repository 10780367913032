.mapContainer {
  width: 90%;
  margin: 0 auto;

  position: relative;
  padding-bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  color: #ffdd2a;
  text-align: center;
  font-family: Montserrat;
  font-size: 60px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  padding-top: 5px;
  padding-bottom: 15px;
  text-shadow: 3px 3px 2px rgba(255, 255, 255, 1);
}
.mapImage {
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: center;
  max-width: 1280px;
  margin: 0 auto;
}
.buttonContainer {
  position: fixed;
  right: 37%;
  bottom: 3%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 2;
}
.button {
  color: #fff;
  text-align: center;
  font-family: DM Sans;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 55px;
  border: 2px solid #fff;
  background: #000;
  outline: none;
  cursor: pointer;
  padding: 12px 36px;
}

@media only screen and (max-width: 1399px) {
  .title {
    font-size: 60px;
  }
  .buttonContainer {
    right: 34%;
  }
  .button {
    font-size: 30px;
  }
}
@media only screen and (max-width: 1199px) {
  .title {
    font-size: 50px;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 1);
  }
  .mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .button {
    font-size: 32px;
  }
}
@media only screen and (max-width: 991px) {
  .title {
    font-size: 40px;
  }
  .buttonContainer {
    bottom: 50px;
  }
  .button {
    font-size: 28px;

    border-width: 2px;
    padding: 12px 30px;
  }
}
@media only screen and (max-width: 575px) {
  /* .mapContainer {
    padding-bottom: 60px;
  } */
  .title {
    font-size: 36px;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 1);
  }
  .buttonContainer {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    justify-content: center;
  }
  .button {
    font-size: 20px;
  }
}
@media only screen and (max-width: 520px) {
  .title {
    font-size: 32px;
  }
  .button {
    font-size: 18px;
  }
}
